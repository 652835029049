import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchCurrentUserData,
  fetchHomeData,
} from "../../../../../../APIs/user";
import AISparkles from "../../../../../../assets/ai_sparkles.svg";
import Map from "../../../../../../assets/map.svg";
import { Home } from "../../../../../../model/Core/Home";

import "./ComparableSalesAnalysis.css";
import { getWithAuth } from "../../../../../../firebase/authentication";
import { UUIDTypes } from "uuid";
import { ComparableSales } from "../../../../../../model/Core/ComparableSales";
import { TOOLS, useToolContext } from "../../../Tools/ToolViewerContext";

const AGNSY_API_BASE_URL = process.env.REACT_APP_AGNSY_SERVER_ADDRESS;
const HTTP_PROTOCOL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

// Default home ID if none is found in the flow
const DEFAULT_HOME_ID = "0224a838-4dfb-41e9-94a4-6607b1f3ad95";

// API function to fetch comparable homes analysis
const fetchComparableSales = async (
  homeId: UUIDTypes
): Promise<ComparableSales> => {
  try {
    const response = await getWithAuth(
      `${HTTP_PROTOCOL}://${AGNSY_API_BASE_URL}/api/ai/comparable-sales/${homeId}`
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch comparable sales: ${response.status}`);
    }

    return (await response.json()) as ComparableSales;
  } catch (error) {
    console.error("Error fetching comparable sales:", error);
    throw error;
  }
};

// Offer and Negotiation Comparable Homes step
const OfferNegotiationComparableHomes: React.FC = () => {
  const [searchStatus, setSearchStatus] = useState<
    "searching" | "successful" | "unsuccessful"
  >("searching");
  const [loading, setLoading] = useState(true);
  const [comparableSalesData, setComparableSalesData] =
    useState<ComparableSales | null>(null);
  const [currentHomeId, setCurrentHomeId] = useState<UUIDTypes | null>(null);
  const [isAIViewOpen, setIsAIViewOpen] = useState(false);
  const [homeData, setHomeData] = useState<Home | null>(null);

  // Get flow parameter from URL
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");

  const { openTool } = useToolContext();

  // Use the user data endpoint to get the home ID from the flow ID
  // Extract homeId from current flow context or use default
  useEffect(() => {
    const fetchCurrentHomeId = async () => {
      try {
        // If flow ID exists, try to get the home ID from user data
        if (flowId) {
          const userData = await fetchCurrentUserData();

          if (userData) {
            const buyingFlowData =
              userData.buying_flows.find((flow) => flow.id === flowId) || null;

            if (buyingFlowData && buyingFlowData.home_id) {
              setCurrentHomeId(buyingFlowData.home_id);
              return;
            }
          }
        }

        // If we get here, either no flow ID, no user data, or no home ID was found
        // Use the default home ID instead
        setCurrentHomeId(DEFAULT_HOME_ID);
      } catch (error) {
        console.error("Error fetching current home ID:", error);
        // Fall back to default home ID on error
        setCurrentHomeId(DEFAULT_HOME_ID);
      }
    };

    fetchCurrentHomeId();
  }, [flowId]);

  // Fetch home data once we have the home ID
  useEffect(() => {
    if (!currentHomeId) return;

    const getHomeData = async () => {
      try {
        const home = await fetchHomeData(currentHomeId);
        if (home) {
          setHomeData(home);
        }
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    };

    getHomeData();
  }, [currentHomeId]);

  // Fetch comparables once we have the home ID
  useEffect(() => {
    if (!currentHomeId) return;

    // Try to fetch the comparable sales for the current home
    const fetchComparables = async () => {
      try {
        setSearchStatus("searching");
        setLoading(true);

        // Fetch comparable sales data
        const data = await fetchComparableSales(currentHomeId);
        setComparableSalesData(data);
        setSearchStatus("successful");
      } catch (error) {
        console.error("Failed to fetch disclosure analysis:", error);
        setSearchStatus("unsuccessful");
      } finally {
        setLoading(false);
      }
    };

    fetchComparables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHomeId]);

  // Handler for opening AI View
  const handleOpenAIView = () => {
    setIsAIViewOpen(true);
  };

  // Handler for closing AI View
  const handleCloseAIView = () => {
    setIsAIViewOpen(false);
  };

  const handleContinueClick = () => {
    const flowIdParam = flowId ? `?flow=${flowId}` : "";
    navigate(`/buyingflow/offer-negotiation/reconnaissance${flowIdParam}`);
  };

  const getDisplayAddress = () => {
    if (!homeData || !homeData.address) {
      return "the property";
    }

    return homeData.address.address_line_1;
  };

  return (
    <div className="comparable-homes">
      <div className="comparable-homes__content">
        <p className="comparable-homes__description">
          Now time to look for nearby comparable sales.
        </p>

        <p className="comparable-homes__search-status">
          Looking for recent sales near {getDisplayAddress()}
        </p>

        {loading ? (
          <div className="comparable-homes__loading">
            <div className="comparable-homes__spinner"></div>
          </div>
        ) : searchStatus === "successful" ? (
          <>
            <div className="comparable-homes__status">
              <span className="comparable-homes__status-icon">✓</span>
              <span className="comparable-homes__status-text">Success</span>
            </div>

            <p className="comparable-homes__message">
              Found comparable home sales.
            </p>

            <div className="comparable-homes__actions">
              <button
                className="comparable-homes__view-ai-button"
                // onClick={handleOpenAIView}
                onClick={() =>
                  openTool(TOOLS.COMPARABLE_SALE, {
                    comparableSales: comparableSalesData,
                  })
                }
              >
                <span className="disclosure-package__ai-icon">
                  <img
                    className="disclosure-package__ai-image"
                    src={AISparkles}
                    alt="ai_sparkles"
                  />
                </span>
                View AI Analysis
              </button>

              <button
                className="disclosure-package__continue-btn"
                onClick={handleContinueClick}
              >
                Continue to Next Step
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="comparable-homes__status">
              <span className="comparable-homes__status-icon">⨯</span>
              <span className="comparable-homes__status-text">
                Unsuccessful
              </span>
            </div>

            <p className="comparable-homes__message">
              No nearby recent sales were found.
            </p>

            <div className="comparable-homes__actions"></div>
          </>
        )}
      </div>
      <img className="comparable-homes__map-image" alt="Map" src={Map} />
    </div>
  );
};

export default OfferNegotiationComparableHomes;
