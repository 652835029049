import React, { useState, useRef, useEffect } from "react";
import Avatar from "../../../../../assets/kia_avatar.svg";
import AISparkles from "../../../../../assets/ai_sparkles.svg";

import "./ai-chat.css";
import { SendLLMMessage, StartLLMSession } from "../../../../../APIs/ai";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { TOOLS, useToolContext } from "../ToolViewerContext";

interface AIMessage {
  role: "user" | "assistant";
  content: string;
}

const AIChatViewer: React.FC = () => {
  const { isToolOpen, currentTool, optionalContext, closeTool } =
    useToolContext();

  const [messages, setMessages] = useState<AIMessage[]>([]);
  const [input, setInput] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [threadId, setThreadId] = useState<string | null>(null); // Allow null initially
  const [hasProcessedInitialPrompt, setHasProcessedInitialPrompt] =
    useState(false); // Track if initialPrompt has been processed

  const sendMessage = async (message: string) => {
    // Loading components
    setIsLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: message },
      { role: "assistant", content: "..." },
    ]);

    try {
      const data = await SendLLMMessage(message, threadId!);

      // Update message after it loads

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          role: "assistant",
          content: data,
        };
        return updatedMessages;
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setIsLoading(false);
  };

  // Create Session
  useEffect(() => {
    const createSession = async () => {
      try {
        const data = await StartLLMSession();
        setThreadId(data); // Set threadId once the session is created
      } catch (error) {
        console.error("Error creating chat session:", error);
      }
    };

    createSession();
  }, []);

  // Auto-scroll when messages are updated
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Process initial prompt after threadId is available
  useEffect(() => {
    if (
      threadId &&
      optionalContext?.initialPrompt &&
      !hasProcessedInitialPrompt
    ) {
      sendMessage(optionalContext.initialPrompt);
      setHasProcessedInitialPrompt(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId, optionalContext, hasProcessedInitialPrompt]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (input.trim()) {
      sendMessage(input);
      setInput("");
    }
  };

  // Conditionall Render
  if (!isToolOpen || currentTool !== TOOLS.AI_CHAT) return null;

  return (
    <div className="ai-chat-viewer">
      <div className="ai-chat-viewer__container">
        <div className="ai-chat-viewer__header">
          <img
            className="ai-chat-viewer__sparkle-icon"
            src={AISparkles}
            alt="chat"
          />

          <p className="ai-chat-viewer__title">Chat</p>

          <div className="ai-chat-viewer__exit" onClick={closeTool}>
            x
          </div>
        </div>

        <div className="ai-chat-viewer__content" ref={chatContainerRef}>
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`ai-chat-viewer__message ${msg.role}
              ${msg.content === "..." ? "loading" : ""}           
            `}
            >
              {msg.role === "assistant" && (
                <div className="ai-chat-viewer__message-icon-container">
                  <img
                    className="ai-chat-viewer__message-icon"
                    src={Avatar}
                    alt="assistant"
                  />
                </div>
              )}

              {msg.content === "..." ? (
                <div className="ai-chat-viewer__loading-dots">
                  <span className="ai-chat-viewer__dot"></span>
                  <span className="ai-chat-viewer__dot"></span>
                  <span className="ai-chat-viewer__dot"></span>
                </div>
              ) : (
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {msg.content}
                </ReactMarkdown>
              )}
            </div>
          ))}
        </div>

        <form className="ai-chat-viewer__prompt-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Ask anything"
            className="ai-chat-viewer__prompt-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            disabled={isLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default AIChatViewer;
