/* React */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

/* Functionality */
import { logoutCurrentUser } from "../../../APIs/user";
import { Auth } from "../../../firebase/authentication";
import { User } from "firebase/auth";

/* Styling */
import "./header.css";
import HeaderLogo from "../../../assets/header_agnsy_logo.svg";

const Header: React.FC = () => {
  /* 
  ================================================================
  CONSTANTS
  ================================================================
  */
  const navigate = useNavigate();
  const location = useLocation();

  /* 
  ================================================================
  STATE VARS
  ================================================================
  */
  const [signedIn, setSignedIn] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  /* 
  ================================================================
  HOOK: user auth
  ================================================================
  */
  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((authUser: User | null) => {
      const userAuthenticated = !!authUser;
      setSignedIn(userAuthenticated);
      localStorage.setItem("isAuthenticated", userAuthenticated.toString());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  /* 
  ================================================================
  HOOK: calculate location
  ================================================================
  */
  useEffect(() => {
    setIsLanding(location.pathname === "/");
  }, [location.pathname]);

  /* 
  ================================================================
  EVENT HANDLER: Logout
  ================================================================
  */
  const handleLogout = () => {
    logoutCurrentUser().then(() => {
      navigate("/");
    });
  };

  /* 
  ================================================================
  RENDER
  ================================================================
  */
  return (
    <header
      className={`agnsy-header ${isLanding ? "agnsy-header--landing" : ""}`}
    >
      <div className="agnsy-header__content">
        <div className="agnsy-header__left">
          <Link to="/buyingflow/homesearch" className="agnsy-header__text">
            Search
          </Link>

          <div
            className="agnsy-header__text"
            onClick={() => alert("Available after MVP!")}
          >
            Guides
          </div>
        </div>

        <div className="agnsy-header__logo-container">
          <Link to="/">
            <img
              className="agnsy-header__logo"
              src={HeaderLogo}
              alt="Agensy Logo"
            />
          </Link>
        </div>

        <div className="agnsy-header__right">
          {signedIn ? (
            <Link to="/" className="agnsy-header__text" onClick={handleLogout}>
              Logout
            </Link>
          ) : (
            <Link to="/login" className="agnsy-header__text">
              Login
            </Link>
          )}

          <div
            className="agnsy-header__text"
            onClick={() => alert("Available after MVP!")}
          >
            Blog
          </div>
        </div>

        {/* 

        <div className="agnsy-header__right">
          
          

          {signedIn && (
            <Link to="/" className="agnsy-header__text" onClick={handleLogout}>
              Logout
            </Link>
          )}
          {signedIn ? (
            <Link to="/dashboard" className="agnsy-header__my-homes">
              My Homes
            </Link>
          ) : (
            <Link to="/login" className="agnsy-header__my-homes">
              Login
            </Link>
          )}
        </div> */}
      </div>
    </header>
  );
};

export default Header;
